(function( $ ) {
$(document).ready(function() {
    //Make header sticky
    window.onscroll = function() {makeSticky()};
    var header = document.querySelector('.navbar-viday');
    var sub = document.querySelector('.sub-nav');
    var sticky = header.offsetTop;

   function makeSticky() {
    if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
        if($(window).width() > 990) {
         $(sub).fadeOut()
        }
    } else {
        header.classList.remove("sticky");
        if($(window).width() > 990) {
            $(sub).fadeIn();
        }        
    }
   }

   $('#knowmore').click(function(){
    event.preventDefault();

    $('html, body').animate({
        scrollTop: $('#knowmore').offset().top - 100
    }, 500);
   });

   $('#seemore').click(function(){
    event.preventDefault();

    $('html, body').animate({
        scrollTop: $('.projects').offset().top - 100
    }, 500);
   });

   //Whitepaper code
   $( "#whitepaperForm" ).submit(function( event ) {
    event.preventDefault();
    console.log('form');
    var name = $("#whitepaperForm input[name='name']").val();
    var email = $("#whitepaperForm input[name='email']").val();
    $.post( "/wp-content/themes/viday/inc/whitepaper.php", {name: name, email: email}, function( data ) {
        if(data == 'done'){
            window.open('/download/whitepaper_viday.pdf', 'name'); 
            $("#whitepaperForm input[name='name']").val('');
            $("#whitepaperForm input[name='email']").val('');
        }
      });
  });


  });
})( jQuery ); 